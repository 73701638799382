import moment from 'moment'
export function getDateFormatString (data) {
  var date = moment(data).format('YYYY-MM-DD').toString()
  return date
}

export function getTimeFormatString (data) {
  var time = moment(data).format('HH:mm').toString()
  return time
}

export function getDateAndTimeUtcFormat (date, time) {
  var dateAndTime = moment.utc(moment(date).format('YY-MM-DD').toString() + ' ' + time).toString()
  return dateAndTime
}

export function getDateAndTimeLocal (date, time) {
  return moment(moment(date).format('YY-MM-DD').toString() + ' ' + time).toString()
}

export function getTodaysDate () {
  return moment().format('YYYY-MM-DD').toString()
}

export function minutesToAddFromTotalTime (fromDate, fromTime, endDate, toTime) {
  let startTime = new Date(fromDate + ' ' + fromTime)
  let endTime = new Date(endDate + ' ' + toTime)
  let totalWorkingHours = startTime - endTime
  let minutesToAdd = Math.abs(totalWorkingHours / (1000 * 60 * 2))
  return [minutesToAdd, startTime, endTime]
}
export function subtractOneDay (date) {
  let data = moment(date).subtract(1, 'days').format('YYYY-MM-DD').toString()
  return data
}

export function addOneDay (date) {
  let data = moment(date).add(1, 'days').format('YYYY-MM-DD').toString()
  return data
}

export function getDayAccordingToLimits (limit) {
  return new Promise((resolve, reject) => {
    console.log('reject', reject)
    let currentDate = new Date()
    let currentDate1 = new Date()
    let y = ''
    let m = ''
    let obj = {
      startDate: currentDate,
      endDate: currentDate1
    }
    // const first = currentDate.getDate() - currentDate.getDay()
    const d = new Date()
    switch (limit) {
      case 'today':
        break
      case 'yesterday':
        obj.startDate = new Date(currentDate.setDate(currentDate.getDate() - 1))
        obj.endDate = new Date(currentDate)
        break
      case 'thisWeek':
        obj.startDate = new Date(d.getTime() - ( 60 * 60 * 24 * 6 *1000 ));
        obj.endDate = new Date(currentDate)
        // obj.startDate = new Date(currentDate.setDate(first))
        // console.log('this.week', first)
        // obj.endDate = new Date(currentDate.setDate(new Date(obj.startDate).getDate() + 6))
        break
      case 'lastWeek':
        obj.endDate = new Date(d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000))
        obj.startDate = new Date(d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000))
        break
      case 'last7Days':
        obj.startDate = new Date(obj.startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        obj.endDate = new Date(currentDate.setDate(currentDate.getDate() - 1))
        break
      case 'thisMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, m, 1)
        obj.endDate = new Date(currentDate)
        break
      case 'lastMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, (m - 1), 1)
        obj.endDate = new Date(y, m, 0)
        break
      case 'next30Days':
        y = currentDate1.setDate(currentDate.getDate() + 29)
        obj.endDate = new Date(y)
        break
      case 'next60Days':
        y = currentDate1.setDate(currentDate.getDate() + 59)
        obj.endDate = new Date(y)
        break
      case 'last60Days':
        y = currentDate.setDate(currentDate.getDate() - 59)
        obj.startDate = new Date(y)
        break
      case 'last30Days':
        y = currentDate.setDate(currentDate.getDate() - 29)
        obj.startDate = new Date(y)
        break
      case 'last90Days':
        y = currentDate.setDate(currentDate.getDate() - 89)
        obj.startDate = new Date(y)
        break
      case 'last120Days':
        y = currentDate.setDate(currentDate.getDate() - 119)
        obj.startDate = new Date(y)
        break
      case 'last180Days':
        y = currentDate.setDate(currentDate.getDate() - 179)
        obj.startDate = new Date(y)
        break
      case 'lastYear':
        y = currentDate.setDate(currentDate.getDate() - 364)
        obj.startDate = new Date(y)
        break
      case 'thisYear':
        y = new Date(new Date().getFullYear(), 0, 1);
        obj.startDate = new Date(y)
        obj.endDate = new Date(currentDate)
        break
    }
    obj.startDate = new Date(obj.startDate.setHours(0, 0, 0, 0)).toUTCString()
    obj.endDate = new Date(obj.endDate.setHours(23, 59, 59, 999)).toUTCString()
    resolve(obj)
  })
}
export function getDayAccordingToLimitsDate (limit) {
  return new Promise((resolve, reject) => {
    console.log('reject', reject)
    let currentDate = new Date()
    let currentDate1 = new Date()
    let y = ''
    let m = ''
    let obj = {
      startDate: currentDate,
      endDate: currentDate1
    }
    const d = new Date()
    const first = currentDate.getDate() - currentDate.getDay()
    switch (limit) {
      case 'today':
        break
      case 'yesterday':
        obj.startDate = new Date(currentDate.setDate(currentDate.getDate() - 1))
        obj.endDate = new Date(currentDate)
        break
      case 'thisWeek':
        // let first = currentDate.getDate() - currentDate.getDay()
        obj.startDate = new Date(currentDate.setDate(first))
        console.log('this.week', first)
        obj.endDate = new Date(currentDate.setDate(new Date(obj.startDate).getDate() + 6))
        break
      case 'lastWeek':
        
        obj.endDate = new Date(d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000))
        obj.startDate = new Date(d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000))
        break
      case 'thisMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, m, 1)
        // obj.endDate = new Date(y, m + 1, 0)
        obj.endDate = new Date(currentDate)
        break
      case 'lastMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, (m - 1), 1)
        obj.endDate = new Date(y, m, 0)
        break
      case 'next30Days':
        y = currentDate1.setDate(currentDate.getDate() + 30)
        obj.endDate = new Date(y)
        break
      case 'next60Days':
        y = currentDate1.setDate(currentDate.getDate() + 59)
        obj.endDate = new Date(y)
        break
      case 'last60Days':
        y = currentDate.setDate(currentDate.getDate() - 59)
        obj.startDate = new Date(y)
        break
      case 'last30Days':
        y = currentDate.setDate(currentDate.getDate() - 29)
        obj.startDate = new Date(y)
        break
      case 'last90Days':
        y = currentDate.setDate(currentDate.getDate() - 89)
        obj.startDate = new Date(y)
        break
      case 'last120Days':
        y = currentDate.setDate(currentDate.getDate() - 119)
        obj.startDate = new Date(y)
        break
      case 'last180Days':
        y = currentDate.setDate(currentDate.getDate() - 179)
        obj.startDate = new Date(y)
        break
      case 'lastYear':
        y = currentDate.setDate(currentDate.getDate() - 364)
        obj.startDate = new Date(y)
        break
     case 'thisYear':
        y = new Date(new Date().getFullYear(), 0, 1);
        obj.startDate = new Date(y)
        obj.endDate = new Date(currentDate)
        break
    }
    obj.startDate = moment(new Date(obj.startDate.setHours(0, 0, 0, 0))).format('YYYY-MM-DD')
    obj.endDate = moment(new Date(obj.endDate.setHours(23, 59, 59, 999))).format('YYYY-MM-DD')
    resolve(obj)
  })
}
