<template>
  <div class="border bg-white custom_dialog" style="" >
    <div class="container">
      <div>
          <div>
            <div class="" style="box-shadow: 0 2px 4px 0 rgb(92 91 91 / 20%)">
              <div class="mb-2 rounded px-5 py-2 bg-primary2 text-white heading-2 font-bold flex items-center justify-between">
                <div>
                  <p class="">Select Custom Date(s)</p>
                </div>
                <div>
                  <button class="list_top_card_btn  bg-gray-200 cta-text text-text1 py-1 px-3 mr-2 rounded-3xl" @click="closeDialog(null)">Discard</button>
                  <button class="list_top_card_btn  bg-info cta-text text-text1 py-1 px-3 rounded-3xl" @click="emitSelectedDatesHandler">Apply</button>
                </div>  
              </div>
              <p class="text-error heading-6  pl-2 p-1 pt-0 rounded font-semibold" v-if="showError" style="contain: inline-size;">*The Average Ad Cost data is available only after 2022-10-18, any date range selected before this date will not have the Avg. Ad Cost values</p>
              <p class="text-text2 heading-6  pl-2 p-1 rounded" v-if="appliedFilters.minDate !== '' && appliedFilters.maxDate !== ''">*Available Min Date is {{ appliedFilters.minDate | dateStringFilter}} and Max Date is {{ appliedFilters.maxDate | dateStringFilter}}</p>
                <div class="grid grid-cols-2 justify-items-center pb-4" v-if="!isYearA && showA">
                  <div class="p-2 pr-0 box">
                    <p class="text-primary2 font-semibold mb-2 heading-4">Start Date:</p>
                    <VDatePicker ref="calendar1" v-model='startDate' mode="date" :min-date="appliedFilters.minDate" :max-date="appliedFilters.maxDate" />
                    <!-- <Datepicker :inline="true" v-model="startDate"></Datepicker>
                    <datepickerVue
                      v-model="startDate" :isYear="isYearA"
                      :max="appliedFilters.maxDate"
                      :min="appliedFilters.minDate"
                    >
                    </datepickerVue> -->
                  </div>
                  <div class="px-2 py-2 box">
                    <p class="text-primary2 font-semibold mb-2 heading-4">End Date:</p>
                    <VDatePicker ref="calendar2" v-model='endDate' mode="date" :min-date="appliedFilters.minDate" :max-date="appliedFilters.maxDate"  />
                    <!-- <VDatePicker v-model="endDate" mode="date" /> -->
                    <!-- <Datepicker :inline="true" v-model="endDate"></Datepicker>
                    <datepickerVue v-model="endDate" :isYear="isYearA"
                    :max="appliedFilters.maxDate"
                    :min="appliedFilters.minDate"
                    >
                    </datepickerVue> -->
                  </div>
                </div>
                <div class="grid grid-cols-2 justify-items-center pb-4" v-if="isYearA">
                  <div class="p-2 pr-0 box">
                    <p class="text-primary2 font-semibold mb-2 heading-4">Start Year:</p>
                    <monthOnly @yearSelected1="date1"
                      v-model="startDate" :isYear="isYearA"
                      :max="appliedFilters.maxDate"
                      :min="appliedFilters.minDate"
                    >
                    </monthOnly>
                  </div>
                  <div class="px-2 py-2 box">
                    <p class="text-primary2 font-semibold mb-2 heading-4">End Year:</p>
                    <monthOnly @yearSelected1="date2" :isYear="isYearA"
                    :max="appliedFilters.maxDate"
                    :min="appliedFilters.minDate"
                    >
                    </monthOnly>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
</div>
</template>
<script>
// import moment from 'moment'
// import VDatePicker2 from 'vue2-datepicker'
// import Datepicker from 'vuejs-datepicker';
import monthOnly from '@/View/components/datepicker/monthOnly.vue'
import moment from 'moment'
// import datepickerVue from '@/View/components/datepicker/datepicker.vue'
export default {
  components: {
    monthOnly,
    // VDatePicker2,
    // Datepicker,
    // datepickerVue
  },
  data () {
    return {
      showError: false,
      startDate: '',
      isYearA: false,
      showA: false,
      endDate: ''
    }
  },
  props: ['appliedFilters', 'isYearOnly', 'showWarning'],
  watch: {
    startDate: {
      handler () {
        console.log('startDate___________________', this.startDate)
        if (this.showWarning) {
          if (moment(this.startDate).format('YYYY-MM-DD') < moment('2022-10-18').format('YYYY-MM-DD')) {
            this.showError = true
          } else {
            this.showError = false
          }
        }
      },
      deep: true
    },
    endDate: {
      handler () {
      },
      deep: true
    }
  },
  mounted () {
    console.log('heheheh 1', this.isYearOnly)
    if (this.isYearOnly !== undefined) {
      console.log('heheheh 1')
      this.isYearA = true
    } else {
      console.log('heheheh 2')
      this.isYearA = false
    }
    // this.startDate = this.appliedFilters.startDate !== '' ? this.appliedFilters.startDate : new Date().toISOString().substr(0, 10)
    // this.endDate = this.appliedFilters.endDate !== '' ? this.appliedFilters.endDate : new Date().toISOString().substr(0, 10)
    this.startDate =  new Date(this.appliedFilters.startDate)
    this.endDate = new Date(this.appliedFilters.endDate)
    // this.endDate = this.appliedFilters.endDate !== '' ? moment(new Date(this.appliedFilters.endDate).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})).format('YYYY-MM-DD') : new Date().toISOString().substr(0, 10)
    // this.startDate = this.appliedFilters.startDate !== '' ? this.$options.filters.formatForDatePicker(this.appliedFilters.startDate) : new Date().toISOString().substr(0, 10)
    // this.endDate = this.appliedFilters.endDate !== '' ? this.$options.filters.formatForDatePicker(this.appliedFilters.endDate) : new Date().toISOString().substr(0, 10)
    console.log('this.startDate 11', this.appliedFilters.startDate)
    console.log('this.startDate 12', this.startDate)
    console.log('this.endDate', this.endDate)
    this.showA = true
  },
  methods: {
    date1 (data) {
      console.log('hehehehhe 2 ', data)
      this.startDate = data
    },
    date2 (data) {
      console.log('hehehehhe 43', data)
      this.endDate = data
    },
    emitSelectedDatesHandler () {
      console.log('isYearA', this.isYearA)
      console.log('isYearA', this.startDate)
      console.log('isYearA', this.endDate)
      if (!this.isYearA) {
        let obj = {
          startDate: this.startDate,
          endDate: this.endDate
          // startDate: new Date(new Date(this.startDate).setHours(0, 0, 0, 0)).toUTCString(),
          // endDate: new Date(new Date(this.endDate).setHours(23, 59, 59, 999)).toUTCString()
        }
        this.closeDialog(obj)
      } else {
        let obj = {
          startDate: this.startDate,
          endDate: this.endDate
        }
        this.closeDialog(obj)
      }
    },
    closeDialog (data) {
      this.$emit('closeSelectedDialog', data)
    }
  }
}
</script>
<style scoped>
.popup_overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.445);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
</style>
