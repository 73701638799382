<template>
  <div @click.stop.prevent="checkActive" class="datepicker_layout bg-white justify-center content-center overflow-hidden">
    <div class="datepicker__divider"></div>
      <div :class="viewModals.yearpicker ? 'p-0' : 'py-0 px-3 datepicer_date__body'">
        <div id="yearBodyPicker" class="overflow-y-scroll mt-1 text-center yearpicker_body__wrapper">
          <div class="py-1 px-0 hover_year_bg"
            v-for="data in yearsList"
            :key="data.name"
            :class="data.isDisabled ? 'text-gray2': selectedyear === data.name ? 'year_selected ' : 'hover_date_bg text-black'"
            @click="selectedYearHandler(data)">
            {{data.name}}
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {
  },
  data () {
    return {
      yearsList: [],
      selectedyear: 0,
      refDatepickerHeaders: [{title: 'Sun', val: 0}, {title: 'Mon', val: 1}, {title: 'Tue', val: 2}, {title: 'Wed', val: 3}, {title: 'Thu', val: 4}, {title: 'Fri', val: 5}, {title: 'Sat', val: 6}],
      datepickerHeaders: [],
      viewModals: {
        datepicker: true,
        monthpicker: false,
        yearpicker: false
      },
      monthsList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      columnNoForBackgroundColor: -1,
      currentDate: moment(new Date()).format('YYYY-MM-DD')
    }
  },
  props: ['firstDayOfTheWeek', 'changeBackgroundOfDayInWeek', 'backgroundColor', 'events', 'value', 'min', 'max'],
  model: {
    prop: 'value',
    event: 'input'
  },
  created () {
    let currentYear = new Date(this.currentDate).getFullYear()
    this.generateYearsList(currentYear)
    this.selectedyear = currentYear
  },
  watch: {
  },
  mounted () {
    console.log('this.min', this.min)
    console.log('this.max', this.max)
    // let element = document.getElementsByClassName('year_selected')
    // console.log('element[0]', element[0].offsetTop, element)
    // document.getElementById('yearBodyPicker').scrollTo({top: element[0].offsetTop - 150})
  },
  methods: {
    checkForMaxDate (date) {
      if (this.max === undefined) return false
      return date > this.max
    },
    checkForMinDate (date) {
      if (this.min === undefined) return false
      return date < this.min
    },
    generateYearsList (currentYear) {
      for (let year = currentYear - 20; year < currentYear + 10; year++) {
        let obj = {
          name: year,
          isDisabled: this.checkForMaxDate(year) || this.checkForMinDate(year)
        }
        this.yearsList.push(obj)
      }
    },
    selectedYearHandler (year) {
      if (!year.isDisabled) {
        this.selectedyear = year.name
        this.$emit('yearSelected1', year.name)
      }
    },
    checkActive () {
      console.log('active_date_pick');
    },
    // check if date if greater then max date
  },
  computed: {
  }
}
</script>
<style scoped>
.datepicker_layout {
  font-family: 'Roboto-Regular' !important;
  max-width: 290px !important;
  width: 290px !important;
  height: 300px !important;
  box-shadow: 0 2px 4px 0 rgba(92, 91, 91, 0.2);
}
.datepicker__divider {
  border-bottom: 0.5px solid lightgray;
}
td {
  width: 35px !important;
  height: 35px;
  padding: 0px;
  margin: 0px 1px;
  overflow: hidden;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.datepicer_date__body{
  height: 250px;
}
tbody p {
  cursor: default;
}
thead {
  font-size: 12px;
  font-weight: bold;
  color: gray;
  margin: 0px;
  padding: 0px;
}
table{
  margin: 0px !important;
}
.disabled_td {
  color: grey;
}
.datepicker_event__circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0px 1px;
}
.datepicker_event__count{
  font-size: 8px;
  color: #40E0D0;
}
.hover_date_bg:hover{
    background-color: #E3F2FD;
}
.selected_date_bg {
    background-color: #0277BD;
}
.yearpicker_body__wrapper {
  height: 300px;
  font-family: 'Roboto-Regular';
}
.year_selected {
  color: blue;
  font-size: 25px;
}
.hover_year_bg:hover {
    background-color: #f0f0f0;
}
</style>
