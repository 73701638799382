<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="mb-2">
          <TabCompo @setNewTabActive="tabsSet" :tabsList="tabs" />
        </div>
      </div>
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-9' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <div class="flex items-center">
              <span>{{activeIndex === 0 ? 'Ebay - Accounting Overview' : 'Ebay - Accounting -'}}</span>
              <div v-if="fromApiStartDate !== '' && fromApiEndDate !== '' && firstLoading && !isSelectCustom && !isFilterOpen" class="flex items-center">
                <p class="text-text2 heading-5 pl-2 pt-0.5 font-semibold">
                  <span class="text-text2 heading-5 font-semibold">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-5 font-semibold">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-primary mt-0.5">
                  {{this.selectedValueinText}}
                </div>
              </div>
              <div v-if="dateDiffCount !== '' && firstLoading && isSelectCustom && !isFilterOpen" class="flex items-center">
                <p class="text-text2 heading-5 pl-2 pt-0.5 font-semibold">
                  <span class="text-text2 heading-5 font-semibold">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-5 font-semibold">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-primary mt-0.5">
                  {{this.dateDiffCount}} Days
                </div>
              </div>
            </div>
            <button @click="exportReport()" :class="firstLoading && (reconciliationData > 0 || reportListing.length > 0) ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 centerLine">
              Export to Excel
            </button>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray3 hover:bg-gray4 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div v-show="activeIndex === 0">
            <listingData :firstLoading="firstLoading" :reportListing="reportListing" :startDate="filterObject.startDate" :endDate="filterObject.endDate"></listingData>
          </div>
          <div v-show="activeIndex === 1">
            <reconciliation :firstLoading="firstLoading" :reportListing="reconciliationData" :startDate="filterObject.startDate" :endDate="filterObject.endDate"></reconciliation>
          </div>
        </div>
        <div class="box col-span-3 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-gray3 p-2 items-center justify-between flex h-11 sticky top-0">
              <div class="heading-4 text-white font-bold">Filter</div>
              <span class="centerLine">
                <!-- <div class=" text-white cursor-pointer hover:underline mr-2" @click="resetAll()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Reset</button>
                </div> -->
                <div class=" text-white cursor-pointer hover:underline" @click="applyFilter()">
                  <button style="letter-spacing: 1px;" class="border border-primary bg-primary px-3 py-1 cust_card heading-7">Apply</button>
                </div>
              </span>
            </div> 
            <div class="pt-1">
              <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div>
              <div class="pt-1 pl-1">
                <button style="letter-spacing: 1px"
                v-for="(data, index) in buttonFiltersArray" :key="index"
                  @click="getDateTimeForFilterHandler(index, true)"
                  :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
                  class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
                  >{{data.title}}</button
                >
              </div>
            </div>
            <div class="divider m-1"></div>
            <div class="p-2 my-1 cust_card" >
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">Fulfillment Status</span>
                  <div class="loader2 ease-linear ml-2" v-if="isFulfilmentLoading"></div>
                </div>
                <div class="centerLine pt-2 pb-1 ">
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempFulfilmentArray.length > 0">({{tempFulfilmentArray.length}} Selected)</div>
                  <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempFulfilmentArray.length === 0 && fulfilmentSelectAll">({{fulfilmentListingObj.length}} Selected)</div>
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempFulfilmentArray.length === 0 && !fulfilmentSelectAll">(Total {{fulfilmentListingObj.length}} Fulfillment Status)</div> -->
                  <div class="pr-2 cursor-pointer" @click="showFulfilmentSearch = !showFulfilmentSearch">
                    <span v-if="!showFulfilmentSearch"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showFulfilmentSearch"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetFulfilmentList()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7">Clear</button>
                    <!-- <p class="cursor-pointer" @click="resetFulfilmentList()" v-if="filterObject.fulfillment.length !== 0 || fulfilmentSearch !== ''"><i class="fas fa-solid fa-filter-circle-xmark h-4 w-4 text-gray4"></i></p> -->
                  </div>
                  <span @click="selectedAll('fulfilment')" class="centerLine" v-if="fulfilmentListingObj.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="fulfilmentSelectAll">
                      <div class="text-text1 pl-1 heading-6 cursor-pointer">
                        {{!fulfilmentSelectAll ? 'All' : 'Null'}}
                      </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showFulfilmentSearch">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Fulfilment Search" id="custSearchInput1"
                  v-model="fulfilmentSearch"
                >
                <span v-if="fulfilmentSearch.length > 0" class="cursor-pointer"  @click="clearFulfilment()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto pt-1" style="max-height: 150px !important;" v-if="fulfilmentListingObj.length > 0">
                <div v-for="(data, index) in fulfilmentListingObj" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'fulfilment')">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="fulfilmentListingObj.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2 my-1 cust_card group">
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">Payment Status</span>
                  <div class="loader2 ease-linear ml-2" v-if="isPaymentLoading"></div>
                </div>
                <div class="centerLine  pt-2 pb-1">
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPaymentList.length > 0">({{tempPaymentList.length}} Selected)</div>
                  <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPaymentList.length === 0 && paymentSelAll">({{paymentStatusList.length}} Selected)</div>
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPaymentList.length === 0 && !paymentSelAll">(Total {{paymentStatusList.length}} Payment Status)</div> -->
                  <div class="pr-2 cursor-pointer" @click="showSearchPayment = !showSearchPayment">
                    <span v-if="!showSearchPayment"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showSearchPayment"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2">
                    <button  @click="resetpayment()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                  </div>
                  <span @click="selectedAll('payment')" class="centerLine cursor-pointer" v-if="paymentStatusList.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="paymentSelAll" >
                    <div class="text-text1 pl-1 heading-6 cursor-pointer">
                      {{!paymentSelAll ? 'All' : 'Null'}}
                    </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearchPayment">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Payment Search" id="custSearchInput4"
                  v-model="paymentSearch"
                >
                <span v-if="paymentSearch.length > 0" class="cursor-pointer"  @click="clearPaymentSearch()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto pt-1" style="max-height: 150px !important;" v-if="paymentStatusList.length > 0">
                <div v-for="(data, index) in paymentStatusList" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'payment')">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected">
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="paymentStatusList.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2 my-1 cust_card group" >
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">State Or Province</span>
                  <div class="loader2 ease-linear ml-2" v-if="isStateLoading"></div>
                </div>
                <div class="centerLine  pt-2 pb-1">
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempStateArray.length > 0">({{tempStateArray.length}} Selected)</div>
                  <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempStateArray.length === 0 && stateSelAll">({{parentStateList.length}} Selected)</div>
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempStateArray.length === 0 && !stateSelAll">(Total {{parentStateList.length}} State or Province)</div> -->
                  <div class="pr-2 cursor-pointer" @click="showSearchState = !showSearchState">
                    <span v-if="!showSearchState"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showSearchState"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetState()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                  </div>
                  <span @click="selectedAll('state')" class="centerLine cursor-pointer" v-if="parentStateList.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="stateSelAll" >
                    <div class="text-text1 pl-1 heading-6 cursor-pointer">
                      {{!stateSelAll ? 'All' : 'Null'}}
                    </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearchState">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="State Search" id="custSearchInput2"
                  v-model="stateSearch"
                >
                <span v-if="stateSearch.length > 0" class="cursor-pointer"  @click="clearStateSearch()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto pt-1" style="max-height: 150px !important;" v-if="parentStateList.length > 0">
                <div v-for="(data, index) in parentStateList" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'state')">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="parentStateList.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2 my-1 cust_card group">
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">Postal Code</span>
                  <div class="loader2 ease-linear ml-2" v-if="isZipLoading"></div>
                </div>
                <div class="centerLine pt-2 pb-1">
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPostalArray.length > 0">({{tempPostalArray.length}} Selected)</div>
                  <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPostalArray.length === 0 && postalSelectAll">({{postalCodeListingObj.length}} Selected)</div>
                  <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPostalArray.length === 0 && !postalSelectAll">(Total {{postalCodeListingObj.length}} Postal Code)</div> -->
                  <div class="pr-2 cursor-pointer" @click="showSearchPostalCode = !showSearchPostalCode">
                    <span v-if="!showSearchPostalCode"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showSearchPostalCode"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetZip()" style="letter-spacing: 0.5px;" class="border cursor-pointer  border-gray-400 rounded-md px-2 heading-7">Clear</button>
                  </div>
                  <span @click="selectedAll('postalCode')" class="centerLine cursor-pointer " v-if="postalCodeListingObj.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="postalSelectAll" >
                      <div class="text-text1 pl-1 heading-6 cursor-pointer">
                        {{!postalSelectAll ? 'All' : 'Null'}}
                      </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearchPostalCode">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Zip Search" id="custSearchInput3"
                  v-model="pinSearch"
                >
                <span v-if="pinSearch.length > 0" class="cursor-pointer"  @click="clearPostalSearch()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto pt-1" style="max-height: 150px !important;" v-if="postalCodeListingObj.length > 0">
                <div v-for="(data, index) in postalCodeListingObj" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'postalCode')">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected">
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="postalCodeListingObj.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
</div>
</template>
<script>
import listingData from './component/listing.vue'
import reconciliation from './component/reconciliation.vue'
import TabCompo from '@/View/components/tabs.vue'
import axios from 'axios'
import deboucneMixin from '@/mixins/debounce.js'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    TabCompo,
    reconciliation,
    listingData,
    CustomDateSelector,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      tabs: [
        {name: 'Report', active: true},
        {name: 'Reconciliation', active: false},
      ],
      fromApiStartDate: '',
      fromApiEndDate: '',
      dateDiffCount: '',
      isSelectCustom: false,
      activeIndex: 0,
      showFulfilmentSearch: false,
      showSearchState: false,
      showSearchPostalCode: false,
      showSearchPayment: false,
      tempFulfilmentArray: [],
      tempStateArray: [],
      tempPostalArray: [],
      tempPaymentList: [],
      fulfilmentSelectAll: true,
      stateSelAll: true,
      postalSelectAll: true,
      paymentSelAll: true,
      isFilterOpen: true,
      isRefresh: true,
      fulfilmentSearch: '',
      stateSearch: '',
      pinSearch: '',
      paymentSearch: '',
      searchLoader: false,
      reportListing: [],
      reconciliationData: 0,
      fulfilmentListingObj: [],
      parentStateList: [],
      postalCodeListingObj: [],
      paymentStatusList: [],
      requiredDataListing: [],
      getPaymentDebounce: () => {},
      getPostalDebounce: () => {},
      getStateDebounce: () => {},
      getFulfilmentDebounce: () => {},
      isFulfilmentLoading: false,
      isStateLoading: false,
      isZipLoading: false,
      isPaymentLoading: false,
      pagination: {
        sortBy: 'date',
        descending: true
      },
      buttonFiltersArray: [
        // {
        //   title: 'Today',
        //   selected: false,
        //   value: 'today'
        // },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        // {
        //   title: 'Last Week',
        //   selected: false,
        //   value: 'lastWeek'
        // },
        // {
        //   title: 'Last 7 Days',
        //   selected: false,
        //   value: 'thisWeek'
        // },
        {
          title: 'This Month',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Last 180 Days',
          selected: false,
          value: 'last180Days'
        },
        {
          title: 'Last 365 Days',
          selected: false,
          value: 'lastYear'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      selectedValue: 'last30Days',
      selectedValueinText: 'Last 30 Days',
      filterObject: {
        fulfillment: [],
        state: [],
        postalCode: [],
        payment: [],
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Accounting ◾ eBay'
    // this.$root.$on('setNewTabActive', (index) => {
    //   this.tabsSet(index)
    // })
    this.getPaymentDebounce = this.debounce(function () {
      this.getPaymentListing(false)
    }, 500)
    this.getPostalDebounce = this.debounce(function () {
      this.getPostalCodeLisitng()
    }, 500)
    this.getStateDebounce = this.debounce(function () {
      this.getStateOrProvinceLisitng(false)
    }, 500)
    this.getFulfilmentDebounce = this.debounce(function () {
      this.getFulfilmentList(false)
    }, 500)
    this.cardHight = window.innerHeight - 235
    this.mainHeight = window.innerHeight - 145
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    this.apiCaller()
  },
  watch: {
    fulfilmentSearch: {
      handler() {
        this.getFulfilmentDebounce()
      }
    },
    stateSearch: {
      handler() {
        this.getStateDebounce()
      }
    },
    pinSearch: {
      handler() {
        this.getPostalDebounce()
      }
    },
    paymentSearch: {
      handler() {
        this.getPaymentDebounce()
      }
    },
    tempFulfilmentArray: {
      handler () {
        console.log('tempFulfilmentArray', this.tempFulfilmentArray.length)
        this.filterObject.fulfillment = []
        this.tempFulfilmentArray.forEach(a => {
          this.filterObject.fulfillment.push(a.displayName)
        })
      },
      deep: true
    },
    tempStateArray: {
      handler () {
        console.log('tempStateArray', this.tempStateArray.length)
        this.filterObject.state = []
        this.tempStateArray.forEach(a => {
          this.filterObject.state.push(a.displayName)
        })
      },
      deep: true
    },
    tempPostalArray: {
      handler () {
        console.log('tempPostalArray', this.tempPostalArray.length)
        this.filterObject.postalCode = []
        this.tempPostalArray.forEach(a => {
          this.filterObject.postalCode.push(a.displayName)
        })
      },
      deep: true
    },
    tempPaymentList: {
      handler () {
        console.log('tempPaymentList', this.tempPaymentList.length)
        this.filterObject.payment = []
        this.tempPaymentList.forEach(a => {
          this.filterObject.payment.push(a.displayName)
        })
      },
      deep: true
    },
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    tabsSet (index) {
      this.tabs.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.activeIndex = i
          } else {
              element.active = false
          }
      });
      this.fulfilmentSearch = ''
      this.stateSearch = ''
      this.pinSearch = ''
      this.paymentSearch = ''
      this.fulfilmentSelectAll = true
      this.stateSelAll = true
      this.postalSelectAll = true
      this.paymentSelAll = true
      this.tempFulfilmentArray = []
      this.tempStateArray = []
      this.tempPostalArray = []
      this.tempPaymentList = []
      this.filterObject.fulfillment = []
      this.filterObject.state = []
      this.filterObject.postalCode = []
      this.filterObject.payment = []
      this.reportListing = []
      this.reconciliationData = 0
      this.firstLoading = false
      this.fulfilmentListingObj = []
      this.paymentStatusList = []
      this.parentStateList = []
      this.postalCodeListingObj = []
      this.fromApiStartDate = ''
      this.fromApiEndDate = ''
      this.dateDiffCount = ''
      this.apiCaller()
    },
    selectedAll (value) {
      if (value === 'fulfilment') {
        this.tempFulfilmentArray = []
        if (this.fulfilmentSelectAll === true) {
          this.fulfilmentSelectAll = false
        } else {
          this.fulfilmentSelectAll = true
        }
        if (this.fulfilmentSearch === '') {
          if (this.fulfilmentSelectAll) {
            console.log('stage 1')
            this.tempFulfilmentArray = []
            for (let index = 0; index < this.fulfilmentListingObj.length; index++) {
              this.fulfilmentListingObj[index].isSelected = true
            }
          } else if (!this.fulfilmentSelectAll) {
            this.tempFulfilmentArray = []
            for (let index = 0; index < this.fulfilmentListingObj.length; index++) {
              this.fulfilmentListingObj[index].isSelected = false
              this.tempFulfilmentArray = []
            }
          }
        } else if (this.fulfilmentSearch !== '') {
          if (this.fulfilmentSelectAll) {
            console.log('stage 3')
            for (let index = 0; index < this.fulfilmentListingObj.length; index++) {
              if (this.fulfilmentListingObj[index].isSelected === false) {
                this.fulfilmentListingObj[index].isSelected = true
                this.tempFulfilmentArray.push(this.fulfilmentListingObj[index])
              }
            }
          } else if (!this.fulfilmentSelectAll) {
            console.log('stage 4')
            this.tempFulfilmentArray = []
            for (let J = 0; J < this.fulfilmentListingObj.length; J++) {
              this.fulfilmentListingObj[J].isSelected = false
            }
          }
        }
      } else if (value === 'state') {
        this.tempStateArray = []
        if (this.stateSelAll === true) {
          this.stateSelAll = false
        } else {
          this.stateSelAll = true
        }
        if (this.stateSearch === '') {
          if (this.stateSelAll) {
            console.log('stage 1')
            this.tempStateArray = []
            for (let index = 0; index < this.parentStateList.length; index++) {
              this.parentStateList[index].isSelected = true
            }
          } else if (!this.stateSelAll) {
            this.tempStateArray = []
            for (let index = 0; index < this.parentStateList.length; index++) {
              this.parentStateList[index].isSelected = false
              this.tempStateArray = []
            }
          }
        } else if (this.stateSearch !== '') {
          if (this.stateSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentStateList.length; index++) {
              if (this.parentStateList[index].isSelected === false) {
                this.parentStateList[index].isSelected = true
                this.tempStateArray.push(this.parentStateList[index])
              }
            }
          } else if (!this.stateSelAll) {
            console.log('stage 4')
            this.tempStateArray = []
            for (let J = 0; J < this.parentStateList.length; J++) {
              this.parentStateList[J].isSelected = false
            }
          }
        }
      } else if (value === 'postalCode') {
        this.tempPostalArray = []
        if (this.postalSelectAll === true) {
          this.postalSelectAll = false
        } else {
          this.postalSelectAll = true
        }
        if (this.pinSearch === '') {
          if (this.postalSelectAll) {
            console.log('stage 1')
            this.tempPostalArray = []
            for (let index = 0; index < this.postalCodeListingObj.length; index++) {
              this.postalCodeListingObj[index].isSelected = true
            }
          } else if (!this.postalSelectAll) {
            this.tempPostalArray = []
            for (let index = 0; index < this.postalCodeListingObj.length; index++) {
              this.postalCodeListingObj[index].isSelected = false
              this.tempPostalArray = []
            }
          }
        } else if (this.pinSearch !== '') {
          if (this.postalSelectAll) {
            console.log('stage 3')
            for (let index = 0; index < this.postalCodeListingObj.length; index++) {
              if (this.postalCodeListingObj[index].isSelected === false) {
                this.postalCodeListingObj[index].isSelected = true
                this.tempPostalArray.push(this.postalCodeListingObj[index])
              }
            }
          } else if (!this.postalSelectAll) {
            console.log('stage 4')
            this.tempPostalArray = []
            for (let J = 0; J < this.postalCodeListingObj.length; J++) {
              this.postalCodeListingObj[J].isSelected = false
            }
          }
        }
      } else if (value === 'payment') {
        this.tempPaymentList = []
        if (this.paymentSelAll === true) {
          this.paymentSelAll = false
        } else {
          this.paymentSelAll = true
        }
        if (this.paymentSearch === '') {
          if (this.paymentSelAll) {
            console.log('stage 1')
            this.tempPaymentList = []
            for (let index = 0; index < this.paymentStatusList.length; index++) {
              this.paymentStatusList[index].isSelected = true
            }
          } else if (!this.paymentSelAll) {
            this.tempPaymentList = []
            for (let index = 0; index < this.paymentStatusList.length; index++) {
              this.paymentStatusList[index].isSelected = false
              this.tempPaymentList = []
            }
          }
        } else if (this.paymentSearch !== '') {
          if (this.paymentSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.paymentStatusList.length; index++) {
              if (this.paymentStatusList[index].isSelected === false) {
                this.paymentStatusList[index].isSelected = true
                this.tempPaymentList.push(this.paymentStatusList[index])
              }
            }
          } else if (!this.paymentSelAll) {
            console.log('stage 4')
            this.tempPaymentList = []
            for (let J = 0; J < this.paymentStatusList.length; J++) {
              this.paymentStatusList[J].isSelected = false
            }
          }
        }
      }
    },
    resetAll () {
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
      this.resetFulfilmentList()
      this.resetState()
      this.resetZip()
      this.resetpayment()
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.buttonFiltersArray[i].value === 'last30Days') {
          this.selectedValue = 'last30Days'
          this.buttonFiltersArray[i].selected = true
        } else {
          this.buttonFiltersArray[i].selected = false
        }
      }
      this.pagination.sortBy = 'date'
    },
    resetFulfilmentList () {
      this.fulfilmentSearch = ''
      this.fulfilmentSelectAll = false
      this.filterObject.fulfillment = []
      this.tempFulfilmentArray = []
      for (let index = 0; index < this.fulfilmentListingObj.length; index++) {
        this.fulfilmentListingObj[index].isSelected = false
      }
    },
    resetState () {
      this.stateSearch = ''
      this.stateSelAll = false
      this.filterObject.state = []
      this.tempStateArray = []
      for (let index = 0; index < this.parentStateList.length; index++) {
        this.parentStateList[index].isSelected = false
      }
    },
    resetZip () {
      this.pinSearch = ''
      this.postalSelectAll = false
      this.filterObject.postalCode = []
      this.tempPostalArray = []
      for (let index = 0; index < this.postalCodeListingObj.length; index++) {
        this.postalCodeListingObj[index].isSelected = false
      }
    },
    resetpayment () {
      this.paymentSearch = ''
      this.paymentSelAll = false
      this.filterObject.payment = []
      this.tempPaymentList = []
      for (let index = 0; index < this.paymentStatusList.length; index++) {
        this.paymentStatusList[index].isSelected = false
      }
    },
    clearFulfilment () {
      this.fulfilmentSearch = ''
    },
    clearStateSearch () {
      this.stateSearch = ''
    },
    clearPostalSearch () {
      this.pinSearch = ''
    },
    clearPaymentSearch () {
      this.paymentSearch = ''
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
    },
    customDateListener (data) {
      this.showCustomDatepicker = false
      if (data !== null) {
        if (data.startDate === null) {
          this.filterObject.startDate = this.defaultStartDate
        } else {
          this.filterObject.startDate = data.startDate
        }
        if (data.endDate === null) {
          this.filterObject.endDate = this.defaultEndDate
        } else {
          this.filterObject.endDate = data.endDate
        }
        // this.getListing()
      } else {
        this.isSelectCustom = false
      }
    },
    selectData (data, index, value) {
      if (value === 'fulfilment') {
        if (!this.fulfilmentSelectAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempFulfilmentArray.push(data)
          } else {
            const findex = this.tempFulfilmentArray.findIndex(x => x.displayName === data.displayName)
            this.fulfilmentListingObj[index].isSelected = false
            this.tempFulfilmentArray.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempFulfilmentArray = []
          for (let I = 0; I < this.fulfilmentListingObj.length; I++) {
            if (this.fulfilmentListingObj[I].isSelected === true) {
              this.tempFulfilmentArray.push(this.fulfilmentListingObj[I])
            }
            this.fulfilmentSelectAll = false   
          }
        }
        if (this.fulfilmentListingObj.length === this.tempFulfilmentArray.length) {
          this.fulfilmentSelectAll = true
        }
      } else if (value === 'state') {
        if (!this.stateSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempStateArray.push(data)
          } else {
            const findex = this.tempStateArray.findIndex(x => x.displayName === data.displayName)
            this.parentStateList[index].isSelected = false
            this.tempStateArray.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempStateArray = []
          for (let I = 0; I < this.parentStateList.length; I++) {
            if (this.parentStateList[I].isSelected === true) {
              this.tempStateArray.push(this.parentStateList[I])
            }
            this.stateSelAll = false   
          }
        }
        if (this.parentStateList.length === this.tempStateArray.length) {
          this.stateSelAll = true
        }
      } else if (value === 'postalCode') {
        if (!this.postalSelectAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempPostalArray.push(data)
          } else {
            const findex = this.tempPostalArray.findIndex(x => x.displayName === data.displayName)
            this.postalCodeListingObj[index].isSelected = false
            this.tempPostalArray.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempPostalArray = []
          for (let I = 0; I < this.postalCodeListingObj.length; I++) {
            if (this.postalCodeListingObj[I].isSelected === true) {
              this.tempPostalArray.push(this.postalCodeListingObj[I])
            }
            this.postalSelectAll = false   
          }
        }
        if (this.postalCodeListingObj.length === this.tempPostalArray.length) {
          this.postalSelectAll = true
        }
      } else if (value === 'payment') {
        if (!this.paymentSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempPaymentList.push(data)
          } else {
            const findex = this.tempPaymentList.findIndex(x => x.displayName === data.displayName)
            this.paymentStatusList[index].isSelected = false
            this.tempPaymentList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempPaymentList = []
          for (let I = 0; I < this.paymentStatusList.length; I++) {
            if (this.paymentStatusList[I].isSelected === true) {
              this.tempPaymentList.push(this.paymentStatusList[I])
            }
            this.paymentSelAll = false   
          }
        }
        if (this.paymentStatusList.length === this.tempPaymentList.length) {
          this.paymentSelAll = true
        }
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      this.isSelectCustom = false
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
        })
      } else {
        let date = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = date
          this.filterObject.endDate = date
        } else {
          this.showCustomDatepicker = showCustomDatepicker
          this.isSelectCustom = true
        }
      }
    },
    applyFilter() {
      this.isFilterOpen = false
      this.getListing()
    },
    apiCaller () {
      this.isRefresh = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.EbayRequiredData(
        response => {
          this.requiredDataListing = response.Data === null ? [] : response.Data
          this.filterObject.minDate = this.requiredDataListing.minDate
          this.filterObject.maxDate = this.requiredDataListing.maxDate
          this.isRefresh = true
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.getFulfilmentList(true)
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getFulfilmentList (value) {
      this.isRefresh = false
      this.isFulfilmentLoading = true
      API.FulfilmentLisitng(
        this.fulfilmentSearch,
        response => {
          this.fulfilmentListingObj = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempFulfilmentArray.length > 0 && this.filterObject.fulfillment.length > 0) {
            for (let I = 0; I < this.fulfilmentListingObj.length; I++) {
              const findex = this.tempFulfilmentArray.findIndex(x => x.displayName === this.fulfilmentListingObj[I].displayName)
              if (findex > -1) {
                this.fulfilmentListingObj[I].isSelected = true
              }
            }
          } else if (this.fulfilmentSearch === '' && this.tempFulfilmentArray.length === 0) {
            for (let I = 0; I < this.fulfilmentListingObj.length; I++) {
              this.fulfilmentListingObj[I].isSelected = true
            }
          }
          this.isFulfilmentLoading = false
          if (value) {
            this.getPaymentListing(true)
          }
        },
        error => {
          this.isFulfilmentLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getPaymentListing (value) {
      this.isRefresh = false
      this.isPaymentLoading = true
      API.PaymentLisitng(
        this.paymentSearch,
        response => {
          this.paymentStatusList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempPaymentList.length > 0 && this.filterObject.payment.length > 0) {
            for (let I = 0; I < this.paymentStatusList.length; I++) {
              const findex = this.tempPaymentList.findIndex(x => x.displayName === this.paymentStatusList[I].displayName)
              if (findex > -1) {
                this.paymentStatusList[I].isSelected = true
              }
            }
          } else if (this.paymentSearch === '' && this.tempPaymentList.length === 0) {
            for (let I = 0; I < this.paymentStatusList.length; I++) {
              this.paymentStatusList[I].isSelected = true
            }
          }
          if (value) {
            this.getStateOrProvinceLisitng(true)
          }
          this.isPaymentLoading = false
        },
        error => {
          this.isPaymentLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getStateOrProvinceLisitng (value) {
      this.isRefresh = false
      this.isStateLoading = true
      API.StateOrProvinceLisitng(
        this.stateSearch,
        response => {
          this.parentStateList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempStateArray.length > 0 && this.filterObject.state.length > 0) {
            for (let I = 0; I < this.parentStateList.length; I++) {
              const findex = this.tempStateArray.findIndex(x => x.displayName === this.parentStateList[I].displayName)
              if (findex > -1) {
                this.parentStateList[I].isSelected = true
              }
            }
          } else if (this.stateSearch === '' && this.tempStateArray.length === 0) {
            for (let I = 0; I < this.parentStateList.length; I++) {
              this.parentStateList[I].isSelected = true
            }
          }
          this.isStateLoading = false
          if (value) {
            this.getPostalCodeLisitng()
          }
        },
        error => {
          this.isStateLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getPostalCodeLisitng () {
      this.isRefresh = false
      this.isZipLoading = true
      API.PostalCodeLisitng(
        this.pinSearch,
        response => {
          this.postalCodeListingObj = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempPostalArray.length > 0 && this.filterObject.postalCode.length > 0) {
            for (let I = 0; I < this.postalCodeListingObj.length; I++) {
              const findex = this.tempPostalArray.findIndex(x => x.displayName === this.postalCodeListingObj[I].displayName)
              if (findex > -1) {
                this.postalCodeListingObj[I].isSelected = true
              }
            }
          } else if (this.pinSearch === '' && this.tempPostalArray.length === 0) {
            for (let I = 0; I < this.postalCodeListingObj.length; I++) {
              this.postalCodeListingObj[I].isSelected = true
            }
          }
          this.isZipLoading = false
        },
        error => {
          this.isZipLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      if (this.filterObject.fulfillment.length === 0) {
        this.filterObject.fulfillment = []
      }
      if (this.filterObject.state.length === 0) {
        this.filterObject.state = []
      }
      if (this.filterObject.postalCode.length === 0) {
        this.filterObject.postalCode = []
      }
      if (this.filterObject.payment.length === 0) {
        this.filterObject.payment = []
      }
      if (this.activeIndex === 0) {
        API.EbayReportListing(
          utcSTART,
          utcEND,
          this.filterObject.fulfillment,
          this.filterObject.payment,
          this.filterObject.state,
          this.filterObject.postalCode,
          false,
          response => {
            this.reportListing = response.Data === null ? [] : response.Data
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
            } else {
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
              this.getDateCountBetween(response.fromDate, response.toDate)
            }
            this.searchLoader = false
            this.firstLoading = true
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 1) {
        API.EbayReconciliationData(
          utcSTART,
          utcEND,
          this.filterObject.fulfillment,
          this.filterObject.payment,
          this.filterObject.state,
          this.filterObject.postalCode,
          false,
          response => {
            this.reconciliationData = response.Data === null ? 0 : response.Data
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
            } else {
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
              this.getDateCountBetween(response.fromDate, response.toDate)
            }
            this.searchLoader = false
            this.firstLoading = true
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getDateCountBetween (startDate, endDate) {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.dateDiffCount = Difference_In_Days === 0 ? 1 :Difference_In_Days
    },
    async exportReport () {
      if (this.firstLoading && (this.reconciliationData > 0 || this.reportListing.length > 0)) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        this.searchLoader = true
        if (this.filterObject.fulfillment.length === 0) {
          this.filterObject.fulfillment = []
        }
        if (this.filterObject.payment.length === 0) {
          this.filterObject.payment = []
        }
        if (this.filterObject.state.length === 0) {
          this.filterObject.state = []
        }
        if (this.filterObject.postalCode.length === 0) {
          this.filterObject.postalCode = []
        }
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = `${this.activeIndex === 0 ? 'Ebay AccountingOverview-' : 'Ebay Total Receipts-'}` + day1 + '-To-' + day2
        let URL = this.activeIndex === 0 ? 'api/v1/analytics/dpp/ebay/accountingOverview' : 'api/v1/analytics/dpp/ebay/reconciliationInfo'
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('fulfillmentList', this.filterObject.fulfillment.length > 0 ? JSON.stringify(this.filterObject.fulfillment) : '')
        data.append('paymentList', this.filterObject.payment.length > 0 ? JSON.stringify(this.filterObject.payment) : '')
        data.append('stateOrProvinceList', this.filterObject.state.length > 0 ? JSON.stringify(this.filterObject.state) : '')
        data.append('postalCodeList', this.filterObject.postalCode.length > 0 ? JSON.stringify(this.filterObject.postalCode) : '')
        data.append('export', true)
        await this.downloadAttachment(URL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    dateTimeFilter (dateTime) {
      if (dateTime === '') {
        return ''
      }
      var time = new Date(dateTime)
      return moment(time).format('MM/DD/YY hh:mm a')
    },
    amountFormaterWithToFix (amount) {
      let value = parseFloat(amount).toFixed(2)
      // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
  },
  destroyed() {
    localStorage.removeItem('extremeDogDoorFilter')
  },
  beforeDestroy () {
    this.$root.$off("setNewTabActive");
    localStorage.removeItem('extremeDogDoorFilter')
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.val_col {
  min-width: 95px !important;
  width: 95px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.payment_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 130px !important;
  width: 130px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.price_col {
  min-width: 128px !important;
  width: 128px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.col_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col2 {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 130px !important;
  width: 130px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0636c7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.9s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
