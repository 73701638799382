<template>
  <div>
    <div class="h-full">
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder  relative mt-2 bg-white" v-if="firstLoading">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-6 py-1 items-center " style="position: sticky;top: 0;background: white;z-index: 10;">
          <div class="xsmall_col  text-center sticky bg-white" style="min-width:54px;!important">
            <span class="">Index</span>
          </div>
          <div class="small_col2 flex items-center" >
            <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="payment_col flex items-center" >
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="payment_col flex items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="date_col flex items-center" >
            <span class="cursor-pointer" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="val_col flex items-center ">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="val_col flex items-center ">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2  flex items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 12, titleObj[12].sort, titleObj[12].value)">{{titleObj[12].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="price_col flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 13, titleObj[13].sort, titleObj[13].value)">{{titleObj[13].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="price_col flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 14, titleObj[14].sort, titleObj[14].value)">{{titleObj[14].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="price_col flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 15, titleObj[15].sort, titleObj[15].value)">{{titleObj[15].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="price_col flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 16, titleObj[16].sort, titleObj[16].value)">{{titleObj[16].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 17, titleObj[17].sort, titleObj[17].value)">{{titleObj[17].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[17].value) && titleObj[17].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[17].value) && !titleObj[17].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 18, titleObj[18].sort, titleObj[18].value)">{{titleObj[18].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[18].value) && titleObj[18].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[18].value) && !titleObj[18].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 19, titleObj[19].sort, titleObj[19].value)">{{titleObj[19].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[19].value) && titleObj[19].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[19].value) && !titleObj[19].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col2 flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 20, titleObj[20].sort, titleObj[20].value)">{{titleObj[20].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[20].value) && titleObj[20].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[20].value) && !titleObj[20].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="payment_col flex rightText items-center">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 21, titleObj[21].sort, titleObj[21].value)">{{titleObj[21].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[21].value) && titleObj[21].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[21].value) && !titleObj[21].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="val_col flex items-center ">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="xsmall_col flex items-center rightText">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
          <div class="small_col flex items-center rightText">
            <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
            <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
          </div>
        </div>
        <div :style="`max-height:` +  cardHight + `px; !important`" class="">
          <div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-center py-1 cursor-pointer "  v-for="(value, indexA) in reportListing" :key="indexA">
            <div class="xsmall_col  text-center" style="">
              <span class="heading-6-1 text-text1">{{indexA + 1}}</span>
            </div>
            <div class="small_col2 ">
              <span class="heading-6-1 text-text1">{{value.orderId !== '' ? value.orderId : '--'}}</span>
            </div>
            <div class="payment_col  ">
              <span class="heading-6 text-text1">{{value.buyer !== '' ? value.buyer : '--'}}</span>
            </div>
            <div class="payment_col  ">
              <span class="heading-6 text-text1 overFlowParaA">{{value.fulfillmentStatus !== '' ? value.fulfillmentStatus : '--'}}</span>
            </div>
            <div class="date_col  ">
              <span class="heading-6 text-text1 overFlowParaA">{{value.paymentStatus !== '' ? value.paymentStatus : '--'}}</span>
            </div>
            <div class="val_col  ">
              <span class="heading-6 text-text1">{{value.stateOrProvince !== '' ? value.stateOrProvince : '--'}}</span>
            </div>
            <div class="val_col  ">
              <span class="heading-6 text-text1">{{value.postalCode !== '' ? value.postalCode : '--'}}</span>
            </div>
            <div class="small_col2  ">
              <span class="heading-6-1 text-text1 overFlowParaA">{{value.countryCode !== '' ? value.countryCode : '--'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.priceSubtotal !== '' ? amountFormaterWithToFix(value.priceSubtotal) : '0'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.discount !== '' ? amountFormaterWithToFix(value.discount) : '0'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.ebayTax !== '' ? amountFormaterWithToFix(value.ebayTax) : '0'}}</span>
            </div>
            <div class="price_col rightText">
              <span class="heading-6-1 text-text1">{{value.grossDisctTax !== '' ? amountFormaterWithToFix(value.grossDisctTax) : '0'}}</span>
            </div>
            <div class="price_col rightText">
              <span class="heading-6-1 text-text1">{{value.totalMarketplaceFee !== '' ? amountFormaterWithToFix(value.totalMarketplaceFee) : '0'}}</span>
            </div>
            <div class="price_col rightText">
              <span class="heading-6-1 text-text1">{{value.totalFeeBasisAmount !== '' ? amountFormaterWithToFix(value.totalFeeBasisAmount) : '0'}}</span>
            </div>
            <div class="price_col rightText">
              <span class="heading-6-1 text-text1">{{value.totalDueSeller !== '' ? amountFormaterWithToFix(value.totalDueSeller) : '0'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.netSales !== '' ? amountFormaterWithToFix(value.netSales) : '0'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.fundsHold !== '' ? amountFormaterWithToFix(value.fundsHold) : '0'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.fundsRelease !== '' ? amountFormaterWithToFix(value.fundsRelease) : '0'}}</span>
            </div>
            <div class="small_col2 rightText">
              <span class="heading-6-1 text-text1">{{value.netDeposit !== '' ? amountFormaterWithToFix(value.netDeposit) : '0'}}</span>
            </div>
            <div class="payment_col rightText">
              <span class="heading-6-1 text-text1">{{value.tax !== '' ? amountFormaterWithToFix(value.tax) : '0'}}</span>
            </div>
            <div class="val_col">
              <span class="heading-6-1 text-text1">{{value.sku !== '' ? value.sku : '--'}}</span>
            </div>
            <div class="xsmall_col rightText">
              <span class="heading-6-1 text-text1">{{value.qnt !== '' ? value.qnt : '--'}}</span>
            </div>
            <div class="small_col rightText">
              <span class="heading-6-1 text-text1">{{value.date !== '' ? dateTimeFilter(value.date) : '--'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
        <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
import moment from 'moment'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  data () {
    return {
      titleObj: [
        {title: 'Order ID', icon: '', value: 'orderId', sort: true},
        {title: 'Buyer', icon: '', value: 'buyer', sort: true},
        {title: 'Fulfilment Status', icon: '', value: 'fulfillmentStatus', sort: true},
        {title: 'Payment Status', icon: '', value: 'paymentStatus', sort: true},
        {title: 'State or Province', icon: '', value: 'stateOrProvince', sort: true},
        {title: 'Postal Code', icon: '', value: 'postalCode', sort: true},
        {title: 'Country Code', icon: '', value: 'countryCode', sort: true},
        {title: 'Sku', icon: '', value: 'sku', sort: true},
        {title: 'Qnt', icon: '', value: 'qnt', sort: true},
        {title: 'Date Est', icon: '', value: 'date', sort: true},
        {title: 'Price Subtotal', icon: '', value: 'priceSubtotal', sort: true},
        {title: 'Discount', icon: '', value: 'discount', sort: true},
        {title: 'Ebay Tax', icon: '', value: 'ebayTax', sort: true},
        {title: 'Gross+Disct+Tax', icon: '', value: 'grossDisctTax', sort: true},
        {title: 'Total Marketplace Fees', icon: '', value: 'totalMarketplaceFee', sort: true},
        {title: 'Total Fee Basis Amount', icon: '', value: 'totalFeeBasisAmount', sort: true},
        {title: 'Total Due Seller', icon: '', value: 'totalDueSeller', sort: true},
        {title: 'Net Sales', icon: '', value: 'netSales', sort: true},
        {title: 'Funds Hold', icon: '', value: 'fundsHold', sort: true},
        {title: 'Funds Release', icon: '', value: 'fundsRelease', sort: true},
        {title: 'Net Deposit', icon: '', value: 'netDeposit', sort: true},
        {title: 'Tax', icon: '', value: 'tax', sort: true},
      ],
      pagination: {
        sortBy: 'date',
        descending: true
      },
      cardHight: 0,
      mainHeight: 0,
    }
  },
  props: ["firstLoading", "reportListing", "startDate", "endDate"],
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 235
    this.mainHeight = window.innerHeight - 145
    console.log('this.cardHight', this.cardHight, this.mainHeight)
  },
  watch: {
  },
  methods: {
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.reportListing.sort(function (a, b) {
          if (val2 === 'orderId') {
            var nameA = a.orderId
            var nameB = b.orderId
          } else if (val2 === 'buyer') {
            nameA = a.buyer
            nameB = b.buyer
          } else if (val2 === 'fulfillmentStatus') {
            nameA = a.fulfillmentStatus
            nameB = b.fulfillmentStatus
          } else if (val2 === 'paymentStatus') {
            nameA = a.paymentStatus
            nameB = b.paymentStatus
          } else if (val2 === 'stateOrProvince') {
            nameA = a.stateOrProvince
            nameB = b.stateOrProvince
          } else if (val2 === 'postalCode') {
            nameA = a.postalCode
            nameB = b.postalCode
          } else if (val2 === 'countryCode') {
            nameA = a.countryCode
            nameB = b.countryCode
          } else if (val2 === 'sku') {
            nameA = a.sku
            nameB = b.sku
          } else if (val2 === 'qnt') {
            nameA = a.qnt
            nameB = b.qnt
          } else if (val2 === 'date') {
            nameA = a.date
            nameB = b.date
          } else if (val2 === 'priceSubtotal') {
            nameA = a.priceSubtotal
            nameB = b.priceSubtotal
          } else if (val2 === 'discount') {
            nameA = a.discount
            nameB = b.discount
          } else if (val2 === 'ebayTax') {
            nameA = a.ebayTax
            nameB = b.ebayTax
          } else if (val2 === 'grossDisctTax') {
            nameA = a.grossDisctTax
            nameB = b.grossDisctTax
          } else if (val2 === 'totalMarketplaceFee') {
            nameA = a.totalMarketplaceFee
            nameB = b.totalMarketplaceFee
          } else if (val2 === 'totalFeeBasisAmount') {
            nameA = a.totalFeeBasisAmount
            nameB = b.totalFeeBasisAmount
          } else if (val2 === 'totalDueSeller') {
            nameA = a.totalDueSeller
            nameB = b.totalDueSeller
          } else if (val2 === 'netSales') {
            nameA = a.netSales
            nameB = b.netSales
          } else if (val2 === 'fundsHold') {
            nameA = a.fundsHold
            nameB = b.fundsHold
          } else if (val2 === 'fundsRelease') {
            nameA = a.fundsRelease
            nameB = b.fundsRelease
          } else if (val2 === 'netDeposit') {
            nameA = a.netDeposit
            nameB = b.netDeposit
          } else if (val2 === 'tax') {
            nameA = a.tax
            nameB = b.tax
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'orderId') {
            var nameA = a.orderId
            var nameB = b.orderId
          } else if (val2 === 'buyer') {
            nameA = a.buyer
            nameB = b.buyer
          } else if (val2 === 'fulfillmentStatus') {
            nameA = a.fulfillmentStatus
            nameB = b.fulfillmentStatus
          } else if (val2 === 'paymentStatus') {
            nameA = a.paymentStatus
            nameB = b.paymentStatus
          } else if (val2 === 'stateOrProvince') {
            nameA = a.stateOrProvince
            nameB = b.stateOrProvince
          } else if (val2 === 'postalCode') {
            nameA = a.postalCode
            nameB = b.postalCode
          } else if (val2 === 'countryCode') {
            nameA = a.countryCode
            nameB = b.countryCode
          } else if (val2 === 'sku') {
            nameA = a.sku
            nameB = b.sku
          } else if (val2 === 'qnt') {
            nameA = a.qnt
            nameB = b.qnt
          } else if (val2 === 'date') {
            nameA = a.date
            nameB = b.date
          } else if (val2 === 'priceSubtotal') {
            nameA = a.priceSubtotal
            nameB = b.priceSubtotal
          } else if (val2 === 'discount') {
            nameA = a.discount
            nameB = b.discount
          } else if (val2 === 'ebayTax') {
            nameA = a.ebayTax
            nameB = b.ebayTax
          } else if (val2 === 'grossDisctTax') {
            nameA = a.grossDisctTax
            nameB = b.grossDisctTax
          } else if (val2 === 'totalMarketplaceFee') {
            nameA = a.totalMarketplaceFee
            nameB = b.totalMarketplaceFee
          } else if (val2 === 'totalFeeBasisAmount') {
            nameA = a.totalFeeBasisAmount
            nameB = b.totalFeeBasisAmount
          } else if (val2 === 'totalDueSeller') {
            nameA = a.totalDueSeller
            nameB = b.totalDueSeller
          } else if (val2 === 'netSales') {
            nameA = a.netSales
            nameB = b.netSales
          } else if (val2 === 'fundsHold') {
            nameA = a.fundsHold
            nameB = b.fundsHold
          } else if (val2 === 'fundsRelease') {
            nameA = a.fundsRelease
            nameB = b.fundsRelease
          } else if (val2 === 'netDeposit') {
            nameA = a.netDeposit
            nameB = b.netDeposit
          } else if (val2 === 'tax') {
            nameA = a.tax
            nameB = b.tax
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    dateTimeFilter (dateTime) {
      if (dateTime === '') {
        return ''
      }
      var time = new Date(dateTime)
      return moment(time).format('MM/DD/YY hh:mm a')
    },
    amountFormaterWithToFix (amount) {
      let value = parseFloat(amount).toFixed(2)
      // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
  },
  destroyed() {
    localStorage.removeItem('extremeDogDoorFilter')
  },
  beforeDestroy () {
    localStorage.removeItem('extremeDogDoorFilter')
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.val_col {
  min-width: 95px !important;
  width: 95px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.payment_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 130px !important;
  width: 130px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.price_col {
  min-width: 128px !important;
  width: 128px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.col_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col2 {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.overFlowParaA {
  /* word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block; */
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0636c7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.9s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
